import React, { useEffect } from "react"
import MessengerChat from "../components/messenger"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/common/header"
import { Div, Text, Image, Button, Anchor } from "atomize"
import { PrimaryColor } from "../components/colors"
import Page1 from "../images/uCall x Giao Duc_page-0001.jpg"
import Page2 from "../images/uCall x Giao Duc_page-0002.jpg"
import firebase from "gatsby-plugin-firebase"
import { Helmet } from "react-helmet"

const GiaoDuc = () => {
  useEffect(() => {
    firebase.analytics().logEvent("visit_giao_duc")
  }, [])
  return (
    <Layout>
      <MessengerChat />

      <SEO
        title="uCall - Giải pháp callbot gọi tự động cho ngành giáo dục"
        description="uCall - Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
      />
      <Helmet>
        <meta
          property="og:title"
          content="uCall - Giải pháp callbot gọi tự động cho ngành giáo dục"
        />
        <meta
          property="og:description"
          content="Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
        />
      </Helmet>
      <Header layout={"layout-1"} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Giải pháp callbot gọi tự động cho ngành giáo dục
        </Text>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/81vKxptwaeg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <a
          href={Page1}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_1_giao_duc`)
          }}
        >
          <Image src={Page1} w="100%" />
        </a>

        <a
          href={Page2}
          target="_blank"
          onClick={() => {
            firebase.analytics().logEvent(`tap_on_page_2_giao_duc`)
          }}
        >
          <Image src={Page2} w="100%" />
        </a>
        <div className="blink">
          <Anchor
            href="https://growth.ucall.vn/"
            onClick={() => {
              firebase.analytics().logEvent("tap_on_trai_nghiem_ngay_giao_duc")
            }}
            target="_blank"
          >
            <Button
              bg="brand900"
              hoverBg="brand900"
              textColor="medium"
              w="100%"
              rounded="lg"
              m={{ t: "16px", b: "16px" }}
              style={{ letterSpacing: "-0.5px" }}
            >
              Trải Nghiệm Ngay
            </Button>
          </Anchor>
        </div>
        <Text>
          Đối với các doanh nghiệp hoạt động trong lĩnh vực giáo dục, một nhu
          cầu rất phổ biến là gọi điện cho mục đích tuyển sinh, giới thiệu các
          khóa học mới đến học viên tiềm năng. Đồng thời, chăm sóc học viên cũng
          là một khâu quan trọng mà doanh nghiệp hoạt động trong mảng giáo dục
          cần chú ý đến. Các trung tâm có thể gọi điện nhắc lịch học viên trong
          các khóa học, các buổi đào tạo cũng như tư vấn, chăm sóc học viên cũ
          để hiểu thêm nhu cầu của học viên, từ đó có thể giới thiệu các khóa
          học tiếp theo trong lộ trình hay duy trì mối quan hệ tốt đẹp với học
          viên. Một học viên thân thiết có thể mang đến cho doanh nghiệp nhiều
          khách hàng tiềm năng trong tương lai.
        </Text>
        <Text>
          So với các ngành khác, việc educate nhận thức của khách hàng đóng vai
          trò vô cùng quan trọng. Có rất nhiều doanh nghiệp hoạt động trong lĩnh
          vực giáo dục mang lợi thế khác nhau, chính vì vậy, việc chăm sóc khách
          hàng presales cũng như aftersales, giữ mối quan hệ tốt đẹp với khách
          hàng mang lại điểm khác biệt cho doanh nghiệp.
        </Text>
        <Text>
          Tuy nhiên, quy trình chăm sóc khách hàng của doanh nghiệp thường thiếu
          sự tự động hóa, doanh nghiệp phải bỏ ra nhiều chi phí và thời gian để
          đào tạo, tuyển dụng, quản lý đội ngũ telesales cũng như thực hiện các
          chiến dịch telesales. Chất lượng các cuộc gọi cũng không ổn định và
          đồng đều.
        </Text>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Giải Pháp
        </Text>
        <Text>
          Ứng dụng callbot của uCall để tự động hóa các chiến dịch telesales
        </Text>
        <ul>
          <li>Nhanh chóng, dễ dàng tự động gọi hàng ngàn cuộc mỗi ngày.</li>
          <li>Phân loại lead tiềm năng và không tiềm năng</li>
          <li>
            Lưu trữ, cập nhật và báo cáo kết quả cuộc gọi với các thông tin câu
            trả lời được chuyển dưới dạng text Dễ dàng nghe lại cuộc gọi
          </li>
        </ul>
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Kết Quả Đạt Được
        </Text>
        <ul>
          <li>Chăm sóc khách hàng có tính cá nhân hóa</li>
          <li>
            Nhanh chóng, dễ dàng gọi hàng ngàn cuộc mỗi ngày Tối ưu chi phí đến
            70% (so với telesales truyền thống)
          </li>
          <li>Các cuộc gọi có chất lượng đồng đều, thống nhất</li>
          <li>Tích hợp với CRM tạo nên một trải nghiệm toàn diện hơn</li>
          <li>
            Khảo sát, lấy ý kiến học viên Duy trì, gìn giữ mối quan hệ tốt đẹp
            với khách hàng
          </li>
        </ul>
        <Div h="120px" />
      </Div>
      <a href="tel:+84327335877" className="call-to-us">
        <div className="call-to-us__label">Liên Hệ</div>
      </a>
    </Layout>
  )
}

export default GiaoDuc
